<template>
	<div class="main-content">
		<div class="row mt-4">
			<div class="col-12">
				<trip-earnings
					trip-type="completed"
					:request-function="(params) => fetchTrips(params)"
					:show-trip-date="false"
					:show-actions="false"
					></trip-earnings>
			</div>
		</div>
	</div>
</template>

<script>
import TripEarnings from '@/components/modules/trips/TripEarnings'

export default {
  components: {
    TripEarnings
  },
  methods: {
    async fetchTrips (params) {
      this.params = params
      const limit = params ? params.limit : 10
      const page = params ? params.page : 1

      const baseUrl = '/v1/trips/completed'
      let url = `${baseUrl}?limit=${limit}&page=${page}&metadata=true&sort[created_at]=desc&`
      if (params?.range?.from || params?.range?.to) {
        url += `from=${params.range.from}&to=${params.range.to}`
      }
      if (params?.search) {
        url += `&search=${params.search}`
      }
      const result = await this.axios.get(url)
      const data = result.data.data
      return {
        data: data,
        count: result.data?.total ?? result.data?.metadata?.total
      }
    }
  }
}
</script>
