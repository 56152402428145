<template>
	<div class="p-2">
		<div class="card">
			<div class="card-header justify-content-between">
				<div class="font-weight-bold">Trip Earnings</div>
				<download-button :downloading="downloadingReport" label="Download Report"
					@download="downloadReport()"></download-button>
			</div>

			<div class="card-header d-flex align-items-center justify-content-between">
				<div class="input-group input-group-flush d-flex flex-row-reverse">
					<input @keyup.enter.prevent="fetchPartnerRevenueList(true)" v-model.trim="search"
						class="form-control list-search" type="search" placeholder="Search" />
					<span class="input-group-text border-0">
						<i class="fe fe-search" @click.prevent="fetchPartnerRevenueList(true)"></i>
					</span>
				</div>
				<div class="col-auto">
					<v-datepicker style="width: 100%" v-model="filter.range" placeholder="Filter by date" range></v-datepicker>
				</div>
				<!-- <b-col class="my-1"> -->
        <div class="flex items-center gap-x-5">
          <b-form-row label="Sort by:" label-for="initial-sort-select" label-cols-sm="3" label-align-sm="right"
					label-size="sm" class="mb-0">
					<b-form-select id="initial-sort-select" style="width: 100%" v-model="sortDirection"
						:options="['All', 'Active', 'Upcoming', 'Completed']" size="sm"></b-form-select>
				</b-form-row>

        <b-form-row label="Sort by:" label-for="initial-sort-select" label-cols-sm="3" label-align-sm="right"
        label-size="sm" class="mb-0">
        <b-form-select id="initial-sort-select" style="width: 100%" v-model="tripStatus"
          :options="['Settled', 'Un-settled']" size="sm"></b-form-select>
      </b-form-row>
        </div>
				<!-- </b-col> -->
			</div>

			<b-table striped hover selectable responsive show-empty :items="tableData" :fields="fields"
				:current-page="currentPage" :busy="loading">
				<template #table-busy>
					<div class="d-flex flex-column justify-content-center align-items-center">
						<div class="spinner-border" role="status"></div>
						<p class="text-center mt-2"><strong>Loading...</strong></p>
					</div>
				</template>

				<template #empty>
					<p class="text-center text-secondary">No records available</p>
				</template>
				<template #cell(serialNumber)="data">
					<p>
						{{ data.index + 1 + (pageSize * (currentPage - 1)) }}
					</p>
				</template>

				<template #cell(date)="data">
					<span>{{ data.item.metadata.startTime | date('d MMMM, yyyy') }}</span>
				</template>

				<template #cell(dateOfCreation)="data">
					<span>{{ data.item.createdAt | date('d MMMM, yyyy') }}</span>
					<div v-if="data.item.metadata.actor">
						<span id="settled-tag">Marked by: {{ data.item.metadata.actor.fname }}
							{{ data.item.metadata.actor.lname }}</span>
						<!-- </div> -->
					</div>
				</template>

				<template #cell(driver)="data">
					<div class="font-weight-bold">
						{{ data.item.metadata?.driver?.fname }}
						{{ data.item.metadata?.driver?.lname }}
					</div>
					<div class="d-flex align-items-center">
						<div class="avatar avatar-xs bus-avatar">
							<img src="@/assets/img/gray_bus.svg" class="avatar-img" alt="Bus" />
						</div>
						<p class="ml-2 mt-2 mb-2">
							{{ data.item.metadata?.vehicle?.brand }}
							{{ data.item.metadata?.vehicle?.name }}
						</p>
					</div>
				</template>

				<template #cell(route)="data">
					<route-description :max-width="250" :pickup="data.item.metadata?.pickup"
						:destination="data.item.metadata?.dropoff">
					</route-description>
				</template>

				<template #cell(route_code)="data">
					<span>{{ data.item.metadata?.routeCode }}</span>
				</template>

				<template #cell(start_time)="data">
					<span>{{ data.item.metadata?.startTime | utcDate('hh:mm aa') }}</span>
				</template>

				<template #cell(earnings)="data">
					<span>&#8358; {{ data.item.finalPartnersRevenue }}</span>
				</template>

				<template #cell(isSettled)="data">
					<b-badge variant="success" v-if="data.item.isSettled">settled</b-badge>
					<b-badge variant="warning" v-else>not settled</b-badge>
					<div v-if="data.item.approvedBy">
						<span id="settled-tag">by: {{ data.item.approvedBy.staffName }}</span>
					</div>
				</template>

				<template #cell(dots)="data">
					<div>
						<b-dropdown :class="data" size="sm" variant="link" toggle-class="text-decoration-none" no-caret no-flip
							class="m-2">
							<template #button-content>
								<p class="mt-3" style="font-weight: 900">&#x2026;</p>
							</template>
							<b-dropdown-item v-b-modal.deduction_modal @click="sendId(data)" class="font-weight-bold" href="#">Deduct
								partner earnings
							</b-dropdown-item>
							<b-dropdown-item v-b-modal.deduction_modal class="font-weight-bold" href="#"
								@click="viewTripFinancials(data.item.tripId)">View Financials
							</b-dropdown-item>
						</b-dropdown>
					</div>
				</template>
			</b-table>
			<b-modal no-close-on-backdrop hide-header-close no-close-on-esc id="deduction_modal"
				title="Deduct partner earnings">
				<p>You are about to deduct from a Partners account</p>
				<form class="pt-4">
					<div class="form-group">
						<label class="form-label text-muted">Amount</label>
						<input v-model.trim="form.amount" required type="number" placeholder="N" class="form-control" />
					</div>
					<div class="form-group">
						<label for="topup-description" class="col-form-label text-muted">Description</label>
						<textarea maxlength="160" :class="[
							form.description.length > descriptionMaxLength
								? 'border border-danger'
								: '',
						]" v-model.trim="form.description" class="form-control" id="topup-description" required></textarea>
						<div class="d-flex justify-content-end align-items-end">
							<small class="text-muted mt-2">{{ form.description.length }} of
								{{ descriptionMaxLength }}</small>
						</div>
					</div>
				</form>

				<template #modal-footer>
					<b-button size="sm" variant="secondary" @click="close">
						Close
					</b-button>
					<b-button @click="handlePartnersDeduction" size="sm" :variant="form.amount && form.description ? 'primary' : 'secondary'">
						{{ modalLoadingState ? 'Processing..' : 'Proceed' }}
					</b-button>
				</template>
			</b-modal>

			<div class="card-footer" v-if="totalRecords">
				<div class="row align-items-center">
					<div class="col">
						<span class="text-dark font-weight-bold">{{ tablePageText }}</span>
					</div>
					<div class="col-auto">
						<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="pageSize">
						</b-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import { extractErrorMessage } from '@/utils/helpers'
import RouteDescription from '@/components/modules/routes/RouteDescription'
import Swal from 'sweetalert2'
import { ExportToCsv } from 'export-to-csv'
import DownloadButton from '@/components/core/DownloadButton'

export default {
  components: { DownloadButton, RouteDescription },
  props: {
    shDownloadButtonowActions: {
      type: Boolean,
      default: true
    },
    showTripDate: {
      type: Boolean,
      default: false
    },
    tripType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      pageSize: 20,
      currentPage: 1,
      totalRecords: 0,
      tripStatus: 'Settled',
      loading: false,
      processing: false,
      tableData: [],
      tableMetadata: {},
      revenueDate: '',
      sortDirection: 'All',
      form: {
        amount: '',
        description: ''
      },
      descriptionMaxLength: 160,
      fields: [
        {
          key: 'serialNumber',
          label: 'S/N'
        },
        {
          key: 'date',
          label: 'Trip Date'
        },
        {
          key: 'dateOfCreation',
          label: 'Date of Creation'
        },
        {
          key: 'start_time',
          label: 'Start Time'
        },
        {
          key: 'driver',
          label: 'Driver'
        },
        {
          key: 'route',
          label: 'Route'
        },
        {
          key: 'route_code',
          label: 'Route Code'
        },
        {
          key: 'earnings',
          label: 'Earnings'
        },
        {
          key: 'isSettled',
          label: 'Status'
        },
        {
          key: 'dots',
          label: ''
        }
      ],
      tripRecordId: '',
      verificationMessage: 'Are you sure you want to end this trip ?',
      showCancelTripModalIndex: -1,
      cancellingTrip: false,
      search: '',
      fetchingPartners: true,
      filter: {
        range: []
      },
      showModal: false,
      downloadingReport: false,
      modalLoadingState: false,
      account_sid: this.$route.params.accountSid
    }
  },
  watch: {
    currentPage () {
      this.fetchPartnerRevenueList()
    },
    search (value) {
      if (!value) {
        this.fetchPartnerRevenueList(true)
      }
    },
    dateFilterValid (value) {
      if (value) {
        this.fetchPartnerRevenueList(true)
      }
    },
    sortDirection (value) {
      if (value) {
        this.fetchPartnerRevenueList(true)
      }
    },
    tripStatus (value) {
      if (value) {
        this.fetchPartnerRevenueList(true)
      }
    },
    'filter.range' (value) {
      if (!value.length || value) {
        this.fetchPartnerRevenueList(true)
      }
    }
  },
  created () {
    this.fetchPartnerRevenueList()
  },
  computed: {
    settlementStatus(){
       return this.tripStatus === 'Settled' ? true : this.tripStatus === 'Un-settled' ? false : ''
    },
    tableColumns () {
      if (this.showTripDate) {
        return this.fields
      } else {
        return this.fields.filter((column) => column.label !== 'Trip Date')
      }
    },
    dateProp () {
      return this.tripType === 'upcoming' ? 'trip_date' : 'start_trip'
    },
    tablePageText () {
      const cpSize = this.pageSize * this.currentPage
      if (this.currentPage === 1 && this.totalRecords <= this.pageSize) {
        return `Showing ${this.totalRecords} record${this.totalRecords > 1 ? 's' : ''
          }`
      } else {
        return this.totalRecords
          ? `Showing ${cpSize - this.pageSize + 1} - ${Math.min(
            cpSize,
            this.totalRecords
          )} of ${this.totalRecords}`
          : ''
      }
    },

    dateFilterValid () {
      return (
        this.filter.range.length &&
        this.filter.range.filter((date) => !!date).length > 0
      )
    },

    dateRange () {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    }
  },
  mounted () {
    this.emitInterface()
  },
  methods: {
    viewTripFinancials (tripId) {
      this.$router.push({ name: 'TripFinancials', params: { tripId: tripId } })
    },

    async fetchAllPartnerRevenueList () {
      const params = {
        page: 1,
        perPage: 1
      }

      if (this.dateFilterValid) {
        const dateRange = this.filter.range.map((date) =>
          moment(date).format('YYYY-MM-DD')
        )

        if (dateRange[0]) {
          params.startDate = dateRange[0]
        }

        if (dateRange[1]) {
          params.endDate = dateRange[1]
        }
      }

      let res = await this.axios.get(
        `cost-revenue/v1/partners/${this.account_sid}/revenues`,
        {
          params
        }
      )

      const totalRecords = res.data.metadata.total

      const newParams = {
        page: 1,
        perPage: totalRecords
      }

      if (this.dateFilterValid) {
        const dateRange = this.filter.range.map((date) =>
          moment(date).format('YYYY-MM-DD')
        )

        if (dateRange[0]) {
          newParams.startDate = dateRange[0]
        }

        if (dateRange[1]) {
          newParams.endDate = dateRange[1]
        }
      }

      res = await this.axios.get(
        `cost-revenue/v1/partners/${this.account_sid}/revenues`,
        {
          params: newParams
        }
      )
      return res.data.result
    },

    async downloadReport () {
      if (this.downloadingReport) return

      try {
        this.downloadingReport = true

        const tableData = await this.fetchAllPartnerRevenueList()

        const csvData = tableData.map((data) => {
          const finalEarning = data.partnersRevenue - data.totalDeductedAmount
          return {
            name: data.metadata.driver.fname + ' ' + data.metadata.driver.lname,
            vehicleType:
              data.metadata.vehicle.brand + ' ' + data.metadata.vehicle.name,
            pickup: data.metadata.pickup,
            dropoff: data.metadata.dropoff,
            routeCode: data.metadata.routeCode,
            earnings: finalEarning,
            start_time: moment(data.metadata.startTime).utc().format('h:mm a'),
            trip_date: moment(data.metadata.startTime).format('Do, MMMM YYYY'),
            created_date: moment(data.createdAt).format('Do, MMMM YYYY')
          }
        })

        const csvParams = {
          fieldSeparator: ',',
          filename: `Partners revenue for ${this.dateRange[0]} to ${this.dateRange[1]}`,
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: `Partners revenue from ${this.dateRange[0]} to ${this.dateRange[1]}`,
          useTextFile: false,
          useBom: true,
          headers: [
            'Name',
            'Vehicle Type',
            'Pick up',
            'Drop off',
            'Route Code',
            'Earnings',
            'Start Time',
            'Trip Date',
            'Created Date'
          ]
        }

        const csvExporter = new ExportToCsv(csvParams)

        csvExporter.generateCsv(csvData)

        this.downloadingReport = false
        this.$swal({
          icon: 'success',
          title: 'Report downloaded',
          text: 'Report has been downloaded successfully',
          showCloseButton: true
        })
      } catch (e) {
        this.$swal({
          icon: 'error',
          title: 'Report download failed',
          text: 'We could not complete this request',
          showCloseButton: true
        })
      } finally {
        this.downloadingReport = false
      }
    },
    sendId (currentTrip) {
      this.tripRecordId = currentTrip.item.id
      this.earningId = currentTrip.item.accruedEarningsId
    },
    close () {
      this.$bvModal.hide('deduction_modal')
      this.form.amount = ''
      this.form.description = ''
    },

    async handlePartnersDeduction () {
      const deductionData = {
        amount: Number(this.form.amount),
        description: this.form.description
        // id: this.tripRecordId ,
      }
      if (this.form.amount === '' || this.form.description === '') {
        return
      }
      this.modalLoadingState = true
      try {
        const res = await this.axios.post(
          `/cost-revenue/v1/revenues/${this.tripRecordId}/deductions`,
          deductionData
        )
        this.$toastr.s(res.data.message, 'Success')
        this.$bvModal.hide('deduction_modal')
        this.fetchPartnerRevenueList()
      } catch (error) {
        this.$toastr.e('Something went wrong', 'Error')
      } finally {
        this.modalLoadingState = false
      }
    },

    showTrip (trip) {
      // TODO (MARQUIS) : Confirm page this logic should navigate to.
      if (this.tripType && this.tripType === 'upcoming') {
        this.$store.dispatch('setSelectedTripData', Number(trip))
        this.$router.push({
          name: 'TripDetails',
          params: { tripId: '__' + trip.id }
        })
      } else {
        this.$router.push({ name: 'TripDetails', params: { tripId: trip.id } })
      }
    },

    async fetchPartnerRevenueList (forceLoad = false) {
      if (forceLoad) {
        this.currentPage = 1
      }

      this.loading = true

      try {
        const params = {
          page: this.currentPage,
          perPage: this.pageSize,
          search: this.search,
          status: this.sortDirection.toLowerCase(),
          isSettled: this.settlementStatus
        }

        if (this.dateFilterValid) {
          const dateRange = this.filter.range.map((date) =>
            moment(date).format('YYYY-MM-DD')
          )

          if (dateRange[0]) {
            params.startDate = dateRange[0]
          }
          if (dateRange[1]) {
            params.endDate = dateRange[1]
          }
        }

        const res = await this.axios.get(
          `cost-revenue/v1/partners/${this.account_sid}/revenues`,
          {
            params
          }
        )
        this.tableData = res.data.result
        this.totalRecords = res.data.metadata.total
      } catch (error) {
        if (error.response.status == 500) {
          this.$toastr.e('Something went wrong', 'Error')
        } else {
          this.$toastr.e('Please contact Admin', 'Error')
        }
      } finally {
        this.loading = false
      }
    },
    refreshTable (searchEnabled = false) {
      if (!searchEnabled) {
        this.search = ''
      }
      this.currentPage = 1
    },

    async startTrip (trip) {
      const time = trip.route.itineraries[0]?.trip_time
      const tripTimeMinus10Minutes = moment(
        `${trip.trip_date} ${time}`
      ).subtract(10, 'minutes')

      // Check if current time is greater than (trip_time - 10 minutes)
      if (!moment().isAfter(tripTimeMinus10Minutes)) {
        this.$swal({
          icon: 'info',
          title: 'Trip can\'t be started',
          text: 'You can only start a trip 10 minutes before start time or after',
          showCloseButton: true
        })
        return
      }
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to start this trip?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .post('/v1/trips', {
              route_id: trip.route.id,
              pickup_coordinate: trip.route.pickup_coordinate,
              driver_id: trip.route.driver.id
            })
            .then(() => {
              this.refreshTable()
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occurred, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Trip started successfully',
            showCloseButton: true
          })
        }
      })
    },
    cancelTrip (trip, passKey) {
      this.cancellingTrip = true
      this.axios
        .delete(`/v1/upcoming-trips/${trip.id}`, {
          data: {
            password: passKey
          }
        })
        .then(() => {
          this.$refs.tripsTable.refresh()
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Trip cancelled successfully',
            showCloseButton: true
          })
          this.cancellingTrip = false
          this.showCancelTripModalIndex = -1
        })
        .catch((error) => {
          this.cancellingTrip = false
          const msg = extractErrorMessage(
            error,
            'An error occurred, please try again.'
          )
          this.$swal().showValidationMessage(msg)
        })
    },
    async endTrip (trip) {
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to end this trip?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/trips/${trip.id}`, {
              route_id: trip.route_id,
              destination_coordinate: trip.destination_coordinate
                ? trip.destination_coordinate
                : '6.5135466666661115, 3.3668908333333337'
            })
            .then(() => {
              this.refreshTable()
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occurred, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Trip ended successfully',
            showCloseButton: true
          })
        }
      })
    },
    viewPassengers (tripData) {
      const dateString = format(
        parseISO(tripData.start_trip || tripData.trip_date),
        'yyyy-MM-dd'
      )
      this.$router.push({
        path: `/routes/${tripData.route_id}/${dateString}/bookings`,
        query: {
          ...(tripData.route_itinerary_id
            ? { itinerary: tripData.route_itinerary_id }
            : {})
        }
      })
    },
    emitInterface () {
      this.$emit('trip-history-interface', {
        refreshTable: () => this.refreshTable()
      })
    },
    openCancelTripModal (message) {
      this.verificationMessage = message
    },
    getActions (data) {
      return {
        endTrip: () => {
          this.endTrip(data.item)
        },
        startTrip: () => {
          this.startTrip(data.item)
        },
        openDelete: (message) => {
          this.showCancelTripModalIndex = data.index
          this.openCancelTripModal(message)
        },
        data: data
      }
    },
    applySearch (event) {
      if (event.key === 'Enter' || event.keyCode === 13) {
        this.refreshTable(true)
      } else if (!this.search) {
        this.refreshTable(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//TODO: Import _b-table partial when merged in for table styling
::v-deep {
  .b-table {
    font-size: 0.875rem;

    & thead {
      tr>th {
        background: transparent;
      }
    }

    & tbody {
      tr>td {
        vertical-align: middle;
      }
    }

    & .cell-center {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}

.b-pagination {
  margin-bottom: 0;
}

.table-responsive {
  margin-bottom: 0;
}

.bus-avatar {
  height: 1rem;
  width: 1rem;
}

// End of TODO

.view-passenger {
  border: 1px solid;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  cursor: pointer;
  font-weight: 500;
  color: #109352;
  font-size: 0.8rem;
}
</style>
